import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import CustomSwitch from '../../components/global/interactions/CustomSwitch'
import LoadingWithBackdrop from '../../components/global/ui/LoadingWithBackdrop'
import BackButton from '../../components/project/navigations/BackButton'
import { useAppDispatch } from '../../stores/store'
import { onUploadImage } from '../upload-service/utils/uploadUtils'
import AddOrEditHRUserForm from './components/AddOrEditHRUserForm'
import { HRUserService } from './services/hr-user/HRUserService'
import { UpdateHRUserDto } from './services/hr-user/types'
import { fetchedHRUserInfoSelector, isGetHRInfoLoadingSelector } from './stores/hr-user/selector'

const initialValues = {
  name: '',
  profileImage: null,
  position: '',
  phoneNumber: '',
  email: '',
  employeeCode: '',
  medicalBIUrl: '',
  subCompanyIdList: [] as string[],
  departmentIdList: [] as string[],
  sectionIdList: [] as string[],
  isActive: true
}

const EditHRUser = () => {
  const [formValues, setFormValues] = useState(initialValues)
  const [isLoading, setIsLoading] = useState(false)

  const { customerId, hrUserId } = useParams()
  const dispatch = useAppDispatch()
  const fetchedHRUserInfo = useSelector(fetchedHRUserInfoSelector)
  const isGetHRInfoLoading = useSelector(isGetHRInfoLoadingSelector)

  const onToggleIsActive = async () => {
    try {
      if (!hrUserId) return
      setIsLoading(true)
      await HRUserService.updateHRUserStatus({ hr: { id: hrUserId, isActive: !formik.values.isActive } })
      onSetToInitialValues()
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  const onSubmitUpdate = async () => {
    try {
      if (!customerId || !hrUserId) return
      setIsLoading(true)
      const imageUrl = await onUploadImage(formik.values.profileImage, 'user', fetchedHRUserInfo?.profileUrl ?? '')
      const updateHRUserDto: UpdateHRUserDto = {
        hr: {
          id: hrUserId,
          profileUrl: imageUrl,
          customerId: customerId,
          name: formik.values.name,
          position: formik.values.position,
          phoneNumber: formik.values.phoneNumber,
          email: formik.values.email,
          employeeCode: formik.values.employeeCode,
          sectionIdList: formik.values.sectionIdList,
          medicalBIUrl: formik.values.medicalBIUrl,
          subCompanyIdList: formik.values.subCompanyIdList,
          departmentIdList: formik.values.departmentIdList
        }
      }

      await HRUserService.updateHRUser(updateHRUserDto)
    } catch (error) {
      formik.resetForm()
    } finally {
      onSetToInitialValues()
      setIsLoading(false)
    }
  }
  const onSetToInitialValues = async (noFetch?: boolean) => {
    let currentHRUserInfo = fetchedHRUserInfo
    if (hrUserId && !noFetch) {
      setIsLoading(true)
      currentHRUserInfo = await dispatch(HRUserService.getHRInfoByIdAsyncThunk(hrUserId)).unwrap()
      setIsLoading(false)
    }
    if (currentHRUserInfo) {
      const fetchedValues = {
        name: currentHRUserInfo.name,
        position: currentHRUserInfo.position,
        phoneNumber: currentHRUserInfo.phoneNumber,
        email: currentHRUserInfo.email,
        employeeCode: currentHRUserInfo.employeeCode,
        isActive: currentHRUserInfo.isActive!,
        profileImage: null,
        medicalBIUrl: currentHRUserInfo.medicalBIUrl,
        subCompanyIdList: currentHRUserInfo.subCompanyIdList,
        departmentIdList: currentHRUserInfo.departmentIdList,
        sectionIdList: currentHRUserInfo.sectionIdList
      }
      setFormValues(fetchedValues)
    }
  }

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    onSubmit: onSubmitUpdate
  })

  useEffect(() => {
    hrUserId && dispatch(HRUserService.getHRInfoByIdAsyncThunk(hrUserId)).unwrap()
  }, [])

  useEffect(() => {
    onSetToInitialValues(true)
  }, [fetchedHRUserInfo])

  return (
    <>
      {(isLoading || isGetHRInfoLoading) && <LoadingWithBackdrop />}
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <BackButton title='แก้ไขผู้ใช้งาน' />
        <CustomSwitch value={formik.values.isActive} onChange={onToggleIsActive} />
      </Box>
      <AddOrEditHRUserForm isEditPage formik={formik} onCancel={onSetToInitialValues} />
    </>
  )
}

export default EditHRUser
