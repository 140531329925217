import { Box, CircularProgress, Grid, styled, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import React, { Fragment, ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import CustomTextField from '../../../../components/global/forms/CustomTextField'
import CustomCheckbox from '../../../../components/global/interactions/CustomCheckbox'
import EditToolBar from '../../../../components/project/interactions/EditToolBar'
import { useAppDispatch } from '../../../../stores/store'
import { HRUserService } from '../../services/hr-user/HRUserService'
import {
  departmentListMasterDataSelector,
  isGetDepartmentListMasterDataLoadingSelector,
  isGetSectionListMasterDataLoadingSelector,
  isGetSubCompanyListMasterDataLoadingSelector,
  sectionListMasterDataSelector,
  subCompanyListMasterDataSelector
} from '../../stores/hr-user/selector'

interface HRUserInfoSectionProps {
  isEditPage: boolean
  isEdit: boolean
  isFormDisabled: boolean
  formik: FormikProps<HRUserFormikType>
  onCancelEdit: () => void
  onEdit: () => void
  onSubmitEdit: () => void
}
type CheckboxField = 'subCompanyIdList' | 'departmentIdList' | 'sectionIdList'

const HRUserInfoSection = ({
  isEditPage,
  formik,
  isEdit,
  isFormDisabled,
  onCancelEdit,
  onEdit,
  onSubmitEdit
}: HRUserInfoSectionProps) => {
  const { customerId } = useParams()
  const dispatch = useAppDispatch()
  const isGetSectionListMasterDataLoading = useSelector(isGetSectionListMasterDataLoadingSelector)
  const isGetSubCompanyListMasterDataLoading = useSelector(isGetSubCompanyListMasterDataLoadingSelector)
  const isGetDepartmentListMasterDataLoading = useSelector(isGetDepartmentListMasterDataLoadingSelector)
  const sectionListMasterData = useSelector(sectionListMasterDataSelector)
  const subCompanyListMasterData = useSelector(subCompanyListMasterDataSelector)
  const departmentListMasterData = useSelector(departmentListMasterDataSelector)

  const MASTERDATA_MAPPER = {
    subCompanyIdList: subCompanyListMasterData,
    departmentIdList: departmentListMasterData,
    sectionIdList: sectionListMasterData
  }

  const onSelectAllCheckbox = (field: CheckboxField) => {
    const currentIdList = (formik.values as any)[field] as string[]
    const currentMasterData = MASTERDATA_MAPPER[field]
    if (currentIdList.length === currentMasterData.length) {
      formik.setFieldValue(field, [])
    } else {
      formik.setFieldValue(
        field,
        currentMasterData.map((item) => item.id)
      )
    }
  }
  const onClickCheckbox = (field: CheckboxField, value: string) => {
    let currentIdList = (formik.values as any)[field] as string[]
    if (currentIdList.includes(value)) {
      currentIdList = currentIdList.filter((id) => id !== value)
    } else {
      currentIdList.push(value)
    }
    formik.setFieldValue(field, currentIdList)
  }
  const renderedLoadedState = (isLoading: boolean, element: ReactNode) => (isLoading ? <CircularProgress /> : element)

  useEffect(() => {
    if (!customerId) return
    dispatch(HRUserService.getDepartmentListMasterDataAsyncThunk(customerId))
    dispatch(HRUserService.getSectionMasterDataAsyncThunk(customerId))
    dispatch(HRUserService.getSubCompanyListMasterDataAsyncThunk(customerId))
  }, [])

  return (
    <Box flex={1} padding={6}>
      <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom={4}>
        <Typography variant='h5'>ข้อมูลผู้ใช้งาน</Typography>
        {isEditPage && (
          <EditToolBar
            isEdit={isEdit}
            onCancel={onCancelEdit}
            onEdit={onEdit}
            onSubmit={onSubmitEdit}
            isDisabledSubmit={isFormDisabled}
          />
        )}
      </Box>
      <Grid container columns={4} spacing={4}>
        <Grid item xs={2}>
          <CustomTextField
            label='ชื่อ-นามสกุล'
            value={formik.values.name}
            onChange={formik.handleChange}
            id='name'
            isRequired
            isDisabled={!isEdit}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomTextField
            label='ตำแหน่ง'
            value={formik.values.position}
            onChange={formik.handleChange}
            id='position'
            isRequired
            isDisabled={!isEdit}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomTextField
            label='เบอร์โทรศัพท์'
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            id='phoneNumber'
            isRequired
            isDisabled={!isEdit}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomTextField
            label='อีเมล'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            id='email'
            isRequired
            isDisabled={!isEdit}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomTextField
            label='รหัสพนักงาน'
            value={formik.values.employeeCode}
            onChange={formik.handleChange}
            id='employeeCode'
            isDisabled={!isEdit}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomTextField
            label='Powered BI URL'
            value={formik.values.medicalBIUrl}
            onChange={formik.handleChange}
            id='medicalBIUrl'
            isDisabled={!isEdit}
          />
        </Grid>
      </Grid>
      <Box marginTop={6}>
        <Typography variant='h5' marginBottom={4}>
          บริษัทย่อย
        </Typography>
        {renderedLoadedState(
          isGetSubCompanyListMasterDataLoading,
          <Fragment>
            <Box marginTop={4}>
              <CustomCheckbox
                label='ทั้งหมด'
                onChange={() => onSelectAllCheckbox('subCompanyIdList')}
                value={subCompanyListMasterData.length === formik.values.subCompanyIdList.length}
                isDisabled={!isEdit}
              />
            </Box>
            <CheckboxGroup>
              {subCompanyListMasterData.map((item) => (
                <CustomCheckbox
                  key={item.id}
                  label={item.label}
                  noBorder
                  onChange={() => onClickCheckbox('subCompanyIdList', item.id)}
                  value={formik.values.subCompanyIdList.includes(item.id)}
                  isDisabled={!isEdit}
                />
              ))}
            </CheckboxGroup>
          </Fragment>
        )}
      </Box>
      <Box marginTop={6}>
        <Typography variant='h5' marginBottom={4}>
          แผนก
        </Typography>
        {renderedLoadedState(
          isGetDepartmentListMasterDataLoading,
          <Fragment>
            <Box marginTop={4}>
              <CustomCheckbox
                label='ทั้งหมด'
                onChange={() => onSelectAllCheckbox('departmentIdList')}
                value={departmentListMasterData.length === formik.values.departmentIdList.length}
                isDisabled={!isEdit}
              />
            </Box>
            <CheckboxGroup>
              {departmentListMasterData.map((item) => (
                <CustomCheckbox
                  key={item.id}
                  label={item.label}
                  noBorder
                  onChange={() => onClickCheckbox('departmentIdList', item.id)}
                  value={formik.values.departmentIdList.includes(item.id)}
                  isDisabled={!isEdit}
                />
              ))}
            </CheckboxGroup>
          </Fragment>
        )}
      </Box>
      <Box marginTop={6}>
        <Typography variant='h5' marginBottom={4}>
          ฝ่าย
        </Typography>
        {renderedLoadedState(
          isGetSectionListMasterDataLoading,
          <Fragment>
            <Box marginTop={4}>
              <CustomCheckbox
                label='ทั้งหมด'
                onChange={() => onSelectAllCheckbox('sectionIdList')}
                value={sectionListMasterData.length === formik.values.sectionIdList.length}
                isDisabled={!isEdit}
              />
            </Box>
            <CheckboxGroup>
              {sectionListMasterData.map((item) => (
                <CustomCheckbox
                  key={item.id}
                  label={item.label}
                  noBorder
                  onChange={() => onClickCheckbox('sectionIdList', item.id)}
                  value={formik.values.sectionIdList.includes(item.id)}
                  isDisabled={!isEdit}
                />
              ))}
            </CheckboxGroup>
          </Fragment>
        )}
      </Box>
    </Box>
  )
}

const CheckboxGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: theme.spacing(4, 0, 0, 4),
  columnGap: theme.spacing(8),
  rowGap: theme.spacing(4)
}))

export default HRUserInfoSection
