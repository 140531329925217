import { Box, BoxProps, styled, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { COLORS, COLORS_THEME } from '../../../../constants/colors'
import { resetCustomerFilterValues } from '../../../../features/customer/stores/company-info/slice'
import { resetEClaimFilterValues } from '../../../../features/e-claim/stores/e-claim-info/slice'
import { resetFlexBenefitFilterValues } from '../../../../features/flex-benefit/stores/slice'
import { resetPlanFilterValues } from '../../../../features/insurance/stores/plan/slice'
import { resetMedicalFilterValues } from '../../../../features/medical/stores/slice'
import ArrowDownIcon from '../../../global/icons/ArrowDownIcon'

interface SidebarButtonProps {
  title: string
  icon?: ReactNode
  path?: string
  isButton: boolean
  isChild?: boolean
  isShowAll?: boolean
  onClick?: () => void
}

interface SidebarButtonBox extends BoxProps {
  isActive: boolean
  isButton: boolean
  isChild: boolean
}

interface IconBoxProps extends BoxProps {
  isActive: boolean
}

interface RotateableBoxProps extends BoxProps {
  isRotate: boolean
}

const SidebarButton = ({
  title,
  icon,
  path,
  isButton = false,
  isChild = false,
  onClick,
  isShowAll = false
}: SidebarButtonProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isActive = location.pathname.includes(path!)

  const onNavigate = () => {
    path && navigate(path)
    if (isActive) return
    dispatch(resetPlanFilterValues())
    dispatch(resetCustomerFilterValues())
    dispatch(resetEClaimFilterValues())
    dispatch(resetFlexBenefitFilterValues())
    dispatch(resetMedicalFilterValues())
  }

  return (
    <Box paddingX={4}>
      <SidebarButtonBox
        isActive={isActive}
        isButton={isButton}
        onClick={isButton ? onNavigate : onClick}
        isChild={isChild}
      >
        <Box display='flex' alignItems='center'>
          {isChild ? (
            <DotBox>
              <Dot className='dot' />
            </DotBox>
          ) : (
            <IconBox isActive={isActive}>{icon}</IconBox>
          )}
          <Typography marginLeft={4} variant='body1'>
            {title}
          </Typography>
        </Box>
        {!isButton && (
          <RotateableBox isRotate={!isShowAll}>
            <ArrowDownIcon color={COLORS.white} />
          </RotateableBox>
        )}
      </SidebarButtonBox>
    </Box>
  )
}

const RotateableBox = styled(Box, {
  shouldForwardProp: (props) => !['isRotate'].includes(props as string)
})<RotateableBoxProps>(({ isRotate }) => ({
  transform: `rotate(${isRotate ? 0 : 180}deg)`,
  transition: 'all .5s'
}))

const dotBoxSize = '36px'
const DotBox = styled(Box)({
  width: dotBoxSize,
  height: dotBoxSize,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

const dotSize = '4px'
const Dot = styled(Box)(() => ({
  width: dotSize,
  height: dotSize,
  borderRadius: '50%',
  transition: 'background-color .3s'
}))

const iconBoxSize = '36px'
const IconBox = styled(Box, {
  shouldForwardProp: (props) => !['isActive'].includes(props as string)
})<IconBoxProps>(({ isActive }) => ({
  width: iconBoxSize,
  height: iconBoxSize,
  backgroundColor: isActive ? COLORS_THEME.primary : COLORS.white,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all .3s'
}))

const SidebarButtonBox = styled(Box, {
  shouldForwardProp: (props) => !['isActive', 'isChild', 'isButton'].includes(props as string)
})<SidebarButtonBox>(({ theme, isButton, isActive, isChild }) => ({
  height: '52px',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  borderRadius: theme.spacing(4),
  justifyContent: 'space-between',
  cursor: 'pointer',
  color: isActive ? COLORS_THEME.primary : 'white',
  transition: 'all .3s',
  backgroundColor: isActive ? COLORS.white : '',
  '& div svg path': {
    fill: isActive ? COLORS.white : COLORS_THEME.primary
  },
  '& div p': {
    fontWeight: isActive ? 600 : isChild ? 'normal' : 500
  },
  '& div div .dot': {
    backgroundColor: isActive ? COLORS_THEME.primary : COLORS.white
  },
  '&:hover': {
    backgroundColor: isButton && COLORS.white,
    color: isButton && COLORS_THEME.primary
  },
  '& .arrow-right > path': {
    stroke: isActive ? COLORS_THEME.primary : COLORS.white,
    transition: 'stroke .3s'
  },
  '&:hover .arrow-right > path': {
    stroke: COLORS_THEME.primary
  },
  '&:hover div div .dot': {
    backgroundColor: COLORS_THEME.primary
  },
  '&:hover div div': {
    backgroundColor: isChild || !isButton ? '' : COLORS_THEME.primary
  },
  '&:hover div svg path': {
    fill: isButton && COLORS.white
  }
}))

export default SidebarButton
